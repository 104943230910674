import React from 'react';
import Loader from 'react-loader-spinner';




const pageLoad = (props) => {

  const primary = localStorage.getItem("jsp-primary")

  return (
    <div className='full-loader d-flex w-100 h-100 justify-content-center align-items-center'>
        <div
        className='loading-container d-flex align-items-center justify-content-center'
        style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        }}
        >
        <div className="text-center p-t-30">
        <Loader className="loader" type="Circles" style={{color: primary}} height="40" width="40"/>
        
            <p className="txt1">
            Please wait...
            </p>
        </div>
        </div>
      
    </div>
    
    //
  )
}

export default pageLoad