export const lightTheme = {
    body: '#f4f5ff',
    color: {
        bgWhite: '#ffffff',
        text: '#000000',
        primary: '#6777ef',
        toggleBorder: '#dceeff',
        background: '#363537',
        buttonShadow: '#c5e0ff',
        buttonBg: '#0076e4',
        buttonText: '#ffffff',
        formControlBg: '#fafaff',
        buttonHover: '#108cff',
        buttonThemedHover: '#ffffff',
        buttonShadowHover: '#9c9c9c',
        navMainLinkBg: '#ffffff',
        textMuted: '#6c757d',
        contentBg: '#ffffff',
        subjectTableBg: '#eaeef6',
        subjectTableText: '#000000',
        gradeBg: '#f2f4f9',
        dropdownText: '#8686FF',
        dropdownHoverBg: '#E1EBFF',
        profileButtonText: '#757575',
        profileButtonTextSelected: '#ffffff',
        dataTableBody: '#f5f5f5',
        dataTableHead: '#e7e7e7',
        selectValid: '#000000',
        dataTableHead: '#f5f7ff',
        scrollbarTrack: '#f5f5f5',
    },
    
}


export const darkTheme = {
    body: '#292929',
    color: {
        bgWhite: '#1a1a1a',
        text: '#ffffff',
        primary: '#108cff',
        toggleBorder: '#8b8b8b',
        background: '#ffffff',
        card: '#1a1a1a',
        buttonShadow: '#616161',
        buttonBg: '#108cff',
        buttonText: '#ffffff',
        formControlBg: '#242424',
        buttonHover: '#0076e4',
        buttonThemedHover: '#ffffff',
        buttonShadowHover: '#5f5f5f',
        navMainLinkBg: '#1a1a1a',
        textMuted: '#dae2e9',
        contentBg: '#242424',
        subjectTableBg: '#303030',
        subjectTableText: '#ffffff',
        gradeBg: '#343a40',
        dropdownText: '#ffffff',
        dropdownHoverBg: '#242424',
        profileButtonText: "#ffffff",
        profileButtonTextSelected: '#ffffff',
        dataTableBody: '#303030',
        dataTableHead: '#141414',
        selectValid: '#c4c4c4',
        dataTableHead: '#242424',
        scrollbarTrack: '#242424',
    },
   
}
