import React from 'react';
import Loader from 'react-loader-spinner';
import {TailSpin} from 'react-loader-spinner'

 const primary = localStorage.getItem("jsp-primary")



const Loading = (props) => {

 
  return (
    <div
      className='loading-container d-flex align-items-center justify-content-center'
      style={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div className="text-center p-t-30">
      <Loader className="loader" type="Circles" color={primary} height="40" width="40"/>
      
        <p className="txt1">
          Please wait...
        </p>
      </div>
    </div>
    //
  )
}


export default Loading