import { configureStore } from '@reduxjs/toolkit'
import sessionStore from './sessionStore'
import themeStore from './themeStore'
import schoolDetailsStore from './schoolDetailsStore'

export default configureStore({
  reducer: {
    sessionId: sessionStore,
    theme: themeStore,
    school: schoolDetailsStore
  },
})