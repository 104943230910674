let user =
  localStorage.getItem('jsp_staff') != undefined
    ? JSON.parse(localStorage.getItem('jsp_staff'))
    : null;
let token =
  localStorage.getItem('jsp_staffToken') != undefined
    ? JSON.parse(localStorage.getItem('jsp_staffToken'))
    : null;
let school =
  localStorage.getItem('school') != undefined
    ? JSON.parse(localStorage.getItem('school'))
    : null;
let jspPrimary = localStorage.getItem('jsp-primary');
let permissions =
  localStorage.getItem('jsp_staff_permissions') != undefined
    ? JSON.parse(localStorage.getItem('jsp_staff_permissions'))
    : null;
let jspTheme = localStorage.getItem('theme');

let jspStaffPermissions = [];
const environment = window.location.hostname;
 
let host;

if(environment === 'localhost') host = 'http://127.0.0.1:8000';else host = 'https://api.jspnigeria.com';

permissions !== null &&
  permissions.forEach((permission) =>
    jspStaffPermissions.push(permission.name)
  );

const data = {
  school: school,
  staff: user != undefined ? user : '',
  jspPrimary: jspPrimary,
  theme: jspTheme,
  jspStaffPermissions: jspStaffPermissions,
  host:  host,
  // host: 'http://192.168.105.116:8005'',
  // host: 'https://applicants.ibbuiceel.jdlabserver.com/',
  // cloud: 'https://filesupload.jdlabserver.com',
  // printHost: 'https://www.jsp.ng/',
  token: token != undefined ? token : '',
  // id: applicant != undefined ? applicant.id : '',
  headers: {
    'Content-Type':'application/json',
    Authorization: `Bearer ${token != undefined ? token : ''}`,
    xtenant: environment === 'localhost' ? 'eum.tespire.co' : environment
     //xtenant: 'eum.jspnigeria.com'
    // xtenant: 'coeminna.jspnigeria.com'
  }
};

export default data;
