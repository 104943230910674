import React from 'react';
import Loader from 'react-loader-spinner';


const primary = localStorage.getItem("jsp-primary")

const Loading = (props) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}
    >
      <Loader type="Circles" style={{ color: `${primary}` }} height="40" width="40" />
      <div className="text-center p-t-30">
        <p className="txt1 text-black">
          Please wait...
        </p>
      </div>
    </div>
    //
  )
}

export default React.memo(Loading);